import { selectorFamily, selector } from 'recoil';
import {jwtDecode} from 'jwt-decode';
import cookie from 'react-cookies';

import {
  twoFactorLogin,
  refetchToken,
  forceLogin,
  updateUser
} from '../Atoms/Login';

import { api, fileapi } from '../../Services/api';
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink
} from '../../Services/firebase';
import { storage, session } from '../../Services/storage';

import { decodeQueryString } from '../../Common/decodeQueryString';

const checkAuthType = selectorFamily({
  key: 'checkAuthType',
  get: (email: string) => async ({ get }) => {
    try {
      get(forceLogin);
      const res: any = await api.POST('auth/signer/auth-type', {
        email
      });
      session.set('type', res.auth_type);
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const loginWithPassword = selectorFamily({
  key: 'loginWithPassword',
  get: ({ email, password }: any) => async ({ get }) => {
    try {
      get(forceLogin);
      const res: any = await api.POST('auth/signer/login', {
        email,
        password
      });

      storage.set('user', JSON.stringify(res.user));
      storage.set('missingDocuments', JSON.stringify(res?.user?.missingDocs || []))
      storage.set('refreshToken', res.refreshToken);
      cookie.save('accessToken', res.token, {
        path: '/',
        expires: new Date(Date.now() + (5 * 60 * 1000))
      });
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const loginWithoutPassword = selector({
  key: 'loginWithoutPassword',
  get: async ({ get }) => {
    const value = get(twoFactorLogin);
    const email = storage.get('userEmail');
    if (email && value === 1) {
      try {
        get(forceLogin);
        const res: any = await api.POST('authContacts/login', { email });

        storage.remove('userEmail');
        storage.set('user', JSON.stringify(res.user));
        storage.set('missingDocuments', JSON.stringify(res?.user?.missingDocs || []))
        storage.set('refreshToken', res.refreshToken);
        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + (5 * 60 * 1000))
        });

        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    } else {
      return null;
    }
  }
});

const refreshAuthToken = selector({
  key: 'refreshAuthToken',
  get: async ({ get }) => {
    const refetchTokenValue = get(refetchToken);
    const refreshToken = storage.get('refreshToken');
    if (refetchTokenValue !== 0 && refreshToken) {
      try {
        const res: any = await api.POST('auth/signer/token', {
          refreshToken
        });

        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + (5 * 60 * 1000))
        });

        return {
          result: res,
          success: true
        };
      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('refreshToken');
        cookie.remove('accessToken', { path: '/' });

        return {
          result: err,
          success: false
        };
      }
    }

    return null;
  }
});

const removeUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
  cookie.remove('accessToken', { path: '/' });
  window.location.reload()
}

const requestCache = {};

const verifyAuthToken = async () => {
  const accessToken = cookie.load('accessToken');
  const refreshToken = storage.get('refreshToken');

  if (requestCache['verifyAuthToken']) {
    return requestCache['verifyAuthToken'];
  }

  const promise = (async () => {
    if (!accessToken) {
      try {
        const res: any = await api.POST('auth/signer/token', { refreshToken });
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59, 999);
        const remainingTime = currentDate.getTime() - new Date().getTime();

        cookie.save('accessToken', res.token, {
          path: '/',
          expires: new Date(Date.now() + remainingTime),
        });
      } catch (error) {
        removeUser();
      }
    }
  })();
  requestCache['verifyAuthToken'] = promise;

  promise.finally(() => {
    delete requestCache['verifyAuthToken'];
  });

  return promise;
};

const sendEmail = selectorFamily({
  key: 'sendEmail',
  get: (email: string) => async () => {
    try {
      await sendSignInLinkToEmail(email);
      return {
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const sendUpdateProfileEmail = selectorFamily({
  key: 'sendUpdateProfileEmail',
  get: (values: any) => async () => {
    try {
      const res: any = await api.POST(
        'auth/signer/sendUpdateProfileEmail',
        values
      );
      return {
        result: res,
        success: true
      };
    } catch (err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const verifyEmail = selectorFamily({
  key: 'verify',
  get: (search: any) => async () => {
    const email = storage.get('userEmail');
    const params = decodeQueryString(search);
    if (email && params && params.apiKey && params.mode === 'signIn') {
      try {
        const res = await isSignInWithEmailLink();
        return res;
      } catch (err) {
        return err;
      }
    } else {
      return null;
    }
  }
});

const changePassword = selectorFamily({
  key: 'changePassword',
  get: (params: any) => async ({ get }) => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.PUT(
          `auth/signer/change-password`,
          params
        );
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const updateProfile = selectorFamily({
  key: 'updateProfile',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken();
        await api.PUT(`signer/edit`, params);
        const updatedUser = await api.GET(`signer/getById/-1`);
        storage.set('user', JSON.stringify(updatedUser));
        return {
          result: updatedUser,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const updateUserSelector = selectorFamily({
  key: 'updateUserSelector',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken();
        await api.PUT(`user/edit`, params);
        const updatedUser = await api.GET(`user/getById/-1`);
        storage.set('user', JSON.stringify(updatedUser));
        return {
          result: updatedUser,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});


const uploadProfilePicture = selectorFamily({
  key: 'uploadProfilePicture',
  get: (params: any) => async () => {
    if (params) {
      try {
        const formdata = new FormData()
        formdata.append('file', params)
        await verifyAuthToken()
        const res: any = await fileapi.POST(`profile-picture`, formdata);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const logout = selector({
  key: 'logout',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT(
        `auth/signer/logout`
      );
      localStorage.removeItem('user');
      localStorage.removeItem('refreshToken');
      cookie.remove('accessToken', { path: '/' });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const getProfile = selector({
  key: 'getProfile',
  get: async ({ get }) => {
    try {
      get(updateUser)
      await verifyAuthToken()
      const res: any = await api.GET(`signer/getById/-1`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});


const resetPasswordRequest = selectorFamily({
  key: 'resetPasswordRequest',
  get: ({ Signer_Email }: any) => async () => {
    try {
      const res: any = await api.PUT('auth/signer/password/resetrequest', { Signer_Email });
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const resetPassword = selectorFamily({
  key: 'resetPassword',
  get: ({ new_password, token }: any) => async () => {
    try {
      const res: any = await api.PUT('auth/signer/resetpassword', { new_password, token });
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const masterLogin = selectorFamily({
  key: 'masterLogin',
  get: ({ token }: any) => async () => {
    try {
      const res: any = await api.POST('auth/signer/master-login', { token });
      storage.set('user', JSON.stringify(res.user));
      storage.set('refreshToken', res.refreshToken);
      cookie.save('accessToken', res.token, {
        path: '/',
        expires: new Date(Date.now() + (5 * 60 * 1000))
      });
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  checkAuthType,
  loginWithPassword,
  loginWithoutPassword,
  refreshAuthToken,
  sendEmail,
  sendUpdateProfileEmail,
  verifyEmail,
  changePassword,
  updateProfile,
  logout,
  getProfile,
  uploadProfilePicture,
  resetPasswordRequest,
  resetPassword,
  verifyAuthToken,
  masterLogin,
  updateUserSelector
};
