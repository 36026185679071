// ResetPasswordForm.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilCallback } from 'recoil';
import { Button, CircularProgress } from '@material-ui/core';
import Box from '@mui/system/Box';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { Fields } from './Fields/Fields';
import { resetPassword } from './../../Recoil/Selectors/Auth';
import {
  initialValues,
  validationSchema
} from '../../Common/Rules/ResetPassword';
import { marginStyles } from '../../Common/Styles/Margins';

const ResetPasswordForm = (params) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const { token } = useParams();

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any) => {
    const { result, success } = await snapshot.getPromise(
      resetPassword({ new_password: values.new_password, token })
    );
    if (success) {
      setAlert({ message: result.message, status: 'success' });
    } else {
      setAlert({ message: 'Failed to Reset Password', status: 'error' });
    }
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          <Fields
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          {alert && (
            <Box my={2}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Link to="/login">
              <Button
                className={marginClasses.mr1}
                color="primary"
                size="small"
              >
                Back to login
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <CircularProgress
                  className={marginClasses.mr2}
                  color="inherit"
                  size={25}
                />
              )}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { ResetPasswordForm };
