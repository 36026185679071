import React from 'react';
import { Box, Typography, Divider, Grid, SvgIcon } from '@material-ui/core';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from 'react-google-maps';
import { MapPin, Phone, Printer, Mail } from 'react-feather';
import { Page } from '../Components/Page';
import { useStyles } from './Styles/Page';
import { cuStyles } from './Styles/ContactUs';
import { marginStyles } from '../Common/Styles/Margins';
import { compose, withStateHandlers } from 'recompose';

const ContactUs = (props) => {
  const classes = useStyles();
  const cuClasses = cuStyles();
  const marginClasses = marginStyles();
  const MapWithAMakredInfoWindow = compose(
    withStateHandlers(() => ({
      isOpen: false,
    }), {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      })
    }),
    withScriptjs,
    withGoogleMap
  )(props =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 34.03130507276935, lng: -118.42046676852885 }}
    >
      <Marker
        position={{ lat: 34.03130507276935, lng: -118.42046676852885 }}
        onClick={props.onToggleOpen}
      >
        {props.isOpen && <InfoWindow onCloseClick={props.onToggleOpen}>
          <div>
            <Typography className={marginClasses.mb2} variant='h5'>Pacific Document Signing Inc.</Typography>
            <Typography variant='body2'>
              10801 National Blvd. Suite #510,<br />
              Los Angeles, CA 90064
            </Typography>
          </div>
        </InfoWindow>}
      </Marker>
    </GoogleMap>
  );

  return (
    <Page className={classes.root} title='Contact Us'>
      <Typography variant='h3'>CONTACT US</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Typography className={marginClasses.mb2} variant='h4'>OFFICE ADDRESS</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <MapWithAMakredInfoWindow
            googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCkppkhUEpmQc1F-IlfI-bceGSuusGcmyY'
            loadingElement={<div />}
            containerElement={<div className={cuClasses.mapContainer} />}
            mapElement={<div className={cuClasses.mapElement} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={marginClasses.mb5} variant='h5'>Pacific Document Signing Inc.</Typography>
          <Box mb={2} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <MapPin />
            </SvgIcon>
            <Typography variant='body2'>
              10801 National Blvd. Suite #510, Los Angeles, CA 90064
            </Typography>
          </Box>
          <Box mb={2} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <Phone />
            </SvgIcon>
            <Typography variant='body2'>
              Tel: (800)884-7587
            </Typography>
          </Box>
          <Box mb={4} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <Mail />
            </SvgIcon>
            <Typography variant='body2'>
              Email: <a href='mailto:info@pacdocsign.com'>info@pacdocsign.com</a>
            </Typography>
          </Box>
          <Typography className={marginClasses.mb5} variant='h5'>Office Hours</Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            MON - FRI : 07:00AM - 07:30PM PST
          </Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            SAT : 08:00AM - 04:00PM PST
          </Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            AFTER HOURS TEL : (310)-597-5511
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

export { ContactUs };
