
import { atom } from 'recoil';
import { getUserInfo } from '../../Common/getUserInfo';

const twoFactorLogin = atom({
  key: 'twoFactorLogin',
  default: 0
});

const userInfo = atom({
  key: 'userInfo',
  default: getUserInfo()
});

const refetchToken = atom({
  key: 'refetchToken',
  default: 0
});

const forceLogin = atom({
  key: 'forceLogin',
  default: 0
});

const updateUser = atom({
  key: 'updateUser',
  default: 0
});

const reloadCreds = atom({
  key: 'reloadCreds',
  default: 0
});

const missingDocsModal = atom({
  key: 'missingDocs',
  default: false
});

const missingPPModal = atom({
  key: 'missingPPModal',
  default: false
});

const forceUploadSignerCreds = atom({
  key: 'forceUploadSignerCreds',
  default: 0
});

export {
  twoFactorLogin,
  userInfo,
  refetchToken,
  forceLogin,
  updateUser,
  reloadCreds,
  missingDocsModal,
  missingPPModal,
  forceUploadSignerCreds
};
