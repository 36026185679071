import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import {
  Typography,
  Card,
  CardContent,
  Button,
  FormHelperText,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Box from '@mui/system/Box';

import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import { Formik } from 'formik';

import { Page } from '../Components/Page';
import { Schema } from '../Common/Rules/ChangePassword';
import { PasswordChecklist } from './../Components/PasswordChecklist/index';

import { changePassword } from '../Recoil/Selectors/Auth';

import { useStyles } from './Styles/ChangePassword';
import { marginStyles } from '../Common/Styles/Margins';

const ChangePassword = () => {
  const classes: any = useStyles();
  const marginClasses = marginStyles();
  const [alert] = useState(null);

  const [passwordChangeSucc, setPasswordChangeSucc] = useState(false);
  const [passwordChangeError, setPasswordChangeErr] = useState(false);
  const [passwordChangeErrorMessage, setpasswordChangeErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = useRecoilCallback(
    ({ snapshot }) => async (values: any, { resetForm }) => {
      const res = await snapshot.getPromise(
        changePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        })
      );

      if (res.success) {
        resetForm();
        const userObj = JSON.parse(localStorage.getItem('user'));
        userObj.password_reset = true;
        localStorage.setItem('user', JSON.stringify(userObj));
        setPasswordChangeSucc(true);
      } else {
        setpasswordChangeErrorMessage(res.result.message);
        setPasswordChangeErr(true);
      }
    }
  );

  const handleClose = () => {
    setPasswordChangeSucc(false);
  };

  const handleErrorSnackBarClose = () => {
    setPasswordChangeErr(false);
  };

  return (
    <Page title="Change Password">
      <div className={classes.PageTitleWrapper}>
        <Typography className={classes.pageTitle}>Change Password</Typography>
      </div>
      <Card classes={{ root: classes.changePasswordCard }}>
        <CardContent>
          <Formik
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validationSchema={Schema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                {alert && (
                  <Box mb={3}>
                    <Alert severity={alert.status}>{alert.message}</Alert>
                  </Box>
                )}
                <TextField
                  label="Current Password"
                  name="currentPassword"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  size="small"
                  fullWidth
                  classes={{ root: classes.passwordField }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  helperText={touched.currentPassword as boolean && errors.currentPassword as string}
                  error={Boolean(
                    touched.currentPassword && errors.currentPassword
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="New Password"
                  name="newPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  size="small"
                  fullWidth
                  classes={{ root: classes.passwordField }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordChecklist minLength={8} value={values.newPassword} iconSize={12} />
                <TextField
                  label="Re-type Password"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  size="small"
                  fullWidth
                  classes={{ root: classes.passwordField }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword as boolean && errors.confirmPassword as string}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
                {errors.submit && (
                  <FormHelperText error>{errors.submit as string}</FormHelperText>
                )}
                <Box mt={3}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth={false}
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={marginClasses.mr2}
                        color="inherit"
                        size={25}
                      />
                    )}
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Snackbar
        open={passwordChangeSucc}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Password is changed successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={passwordChangeError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleErrorSnackBarClose}
      >
        <Alert onClose={handleErrorSnackBarClose} severity="error">
          {passwordChangeErrorMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export { ChangePassword };
