import React, { useState } from 'react';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { PasswordChecklist } from './../../PasswordChecklist/index';

const Fields = ({ values, errors, touched, handleChange, handleBlur }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label='New Password'
          name='new_password'
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={values.new_password}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Confirm Password'
          name='confirm_password'
          variant='outlined'
          type={showConfirmPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirm_password && errors.confirm_password}
          error={Boolean(touched.confirm_password && errors.confirm_password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export { Fields };
